import { Slider, withStyles } from "@material-ui/core";
import React from "react";

import { primaryColor } from "../../../../scss/colors.scss";

const StyledSlider = withStyles({
  root: {
    padding: "15px 0",
  },
  thumb: {
    height: 28,
    width: 3,
    backgroundColor: primaryColor,
    marginTop: -12,
    marginLeft: -1,
    "&:focus, &:hover, &$active": {
      boxShadow: "none",
    },
  },
  track: {
    opacity: 0,
  },
  rail: {
    opacity: 0,
  },
  mark: {
    backgroundColor: "#505050",
    height: 12,
    width: 1.5,
    marginTop: -4,
    fontWeight: "normal",
  },
  markActive: {
    opacity: 1,
  },
})(Slider);

const SliderInput = ({
  value,
  onChange,
  min = 0,
  max = 100,
  isDecimal = false,
  step = 1,
}) => {
  let numberOfMarks = 25;
  if (isDecimal) {
    numberOfMarks = 20;
  }
  const marks = Array(numberOfMarks)
    .fill(null)
    .map((_, index) => ({
      value: !isDecimal
        ? Math.round(min + (index * (max - min)) / (numberOfMarks - 1))
        : min + (index * (max - min)) / (numberOfMarks - 1),
    }));

  return (
    <StyledSlider
      valueLabelDisplay="off"
      marks={marks}
      value={value}
      min={min}
      max={max}
      onChange={(event, newValue) => onChange(newValue)}
      step={step}
    />
  );
};

export default React.memo(SliderInput);
