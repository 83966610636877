import { Box, InputBase, makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import cx from "classnames";

import { useCommonStyles } from "../../../../utils/loan-application.styles";
import { borderColor } from "../../../../scss/colors.scss";

const useStyles = makeStyles({
  defaultNumberInput: {
    padding: "20px 25px",
    width: "88px",
    border: `1px solid ${borderColor}`,
    borderRadius: "2px",
    "& input": {
      padding: "0",
      width: "fit-content",
    },
  },
});

const SingleInput = ({
  label,
  endAdornment,
  startAdornment,
  type = "number",
  value = 0,
  onChange,
  customInputClasses,
}) => {
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const [displayValue, setDisplayValue] = useState(value);

  const handleInputChange = (event) => {
    setDisplayValue(event.target.value);
  };

  const handleInputBlur = (event) => {
    const parsedValue = Number(event.target.value);
    if (!isNaN(parsedValue)) {
      setDisplayValue(parsedValue);
      onChange(parsedValue);
    } else {
      setDisplayValue(0);
      onChange(0);
    }
  };

  return (
    <Box
      display="flex"
      gridColumnGap="8px"
      alignItems="center"
      justifyContent="space-between"
      width={label && "100%"}
    >
      {label && (
        <Typography
          classes={{
            root: cx(
              commonClasses.textOverflowEllipsis,
              commonClasses.widthFitContent
            ),
          }}
        >
          {label}
        </Typography>
      )}
      <InputBase
        type={type}
        value={displayValue}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        classes={{
          root: cx(classes.defaultNumberInput, customInputClasses),
          input: cx(commonClasses.fontSize14, commonClasses.fontWeightBold),
        }}
        endAdornment={
          <Typography
            classes={{
              root: cx(commonClasses.fontSize14, commonClasses.fontWeightBold),
            }}
          >
            {endAdornment}
          </Typography>
        }
        startAdornment={
          <Typography
            classes={{
              root: cx(commonClasses.fontSize14, commonClasses.fontWeightBold),
            }}
          >
            {startAdornment}
          </Typography>
        }
      />
    </Box>
  );
};

export default SingleInput;
