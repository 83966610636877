import React, { useCallback } from "react";
import {
  Box,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import NumberFormat from "react-number-format";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import cx from "classnames";

import SliderInput from "./SliderInput";
import {
  useCommonStyles,
  useOptionStyles,
} from "../../../../utils/loan-application.styles";

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  inputContainer: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      marginTop: "8px",
      flexDirection: "column-reverse",
      gap: "24px",
    },
  },
  actionContainer: {
    display: "flex",
    flexDirection: "column",
    width: "fit-content",
    height: "fit-content",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  buttonContainer: {
    display: "flex",
    width: "fit-content",
    height: "fit-content",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      justifyContent: "space-between",
    },
  },
  inputContainerHasOptionLabel: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "0px",
      flexDirection: "column",
      gap: "8px",
    },
  },
  actionContainerHasOptionLabel: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  buttonContainerHasOptionLabel: {
    [theme.breakpoints.down("xs")]: {
      width: "fit-content",
    },
  },
}));

const BaseMoneyInput = ({
  label,
  options,
  optionLabel,
  isRemovable,
  handleRemove,
  selectedOption,
  setSelectedOption,
  value = 0,
  onChange,
  minValue = 0,
  maxValue = 100000,
  error,
  helperText,
}) => {
  const classes = useStyles();
  const optionClasses = useOptionStyles();
  const commonClasses = useCommonStyles();
  const isMobile = useMediaQuery("(max-width:599px)");

  const handleSliderChange = useCallback(
    (sliderValue) => {
      onChange(sliderValue);
    },
    [onChange]
  );

  const handleInputChange = (event) => {
    const newValue = Number(event.target.value.split(",").join(""));
    if (!isNaN(newValue)) {
      handleSliderChange(newValue);
    }
  };

  const handleSelect = (option) => {
    setSelectedOption(option);
  };

  const generateClasses = (option = "") => {
    return cx(optionClasses.option, {
      [optionClasses.selected]: selectedOption === option,
    });
  };

  return (
    <Box display="flex" flexDirection="column" gridRowGap="16px" width="100%">
      {(label || optionLabel) && (
        <Box display="flex" justifyContent="space-between" paddingRight="16px">
          <Typography
            classes={{
              root: cx(commonClasses.fontSize14, commonClasses.fontWeightBold),
            }}
          >
            {label}
          </Typography>
          <Typography
            classes={{
              root: cx(commonClasses.fontSize14, commonClasses.fontWeightBold),
            }}
          >
            {!isMobile && optionLabel}
          </Typography>
        </Box>
      )}
      <Box
        classes={{
          root: cx(
            classes.inputContainer,
            optionLabel && classes.inputContainerHasOptionLabel
          ),
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          gridRowGap="16px"
          flexGrow="1"
        >
          <TextField
            value={value}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              inputComponent: NumberFormat,
              inputProps: {
                thousandSeparator: true,
                isNumericString: true,
              },
            }}
            error={error}
            helperText={helperText}
            FormHelperTextProps={{
              style: { position: "absolute", bottom: "-20px" },
            }}
          />
          <SliderInput
            onChange={handleSliderChange}
            min={minValue}
            max={maxValue}
            value={value}
          />
        </Box>
        {options?.length && (
          <Box
            classes={{
              root: cx(
                classes.actionContainer,
                optionLabel && classes.actionContainerHasOptionLabel
              ),
            }}
          >
            <Typography
              classes={{
                root: cx(
                  commonClasses.fontSize14,
                  commonClasses.fontWeightBold
                ),
              }}
            >
              {isMobile && optionLabel}
            </Typography>
            <Box
              classes={{
                root: cx(
                  classes.buttonContainer,
                  optionLabel && classes.buttonContainerHasOptionLabel
                ),
              }}
            >
              <Box display="flex">
                {options.map((option) => (
                  <Box
                    key={option.value}
                    classes={{ root: generateClasses(option.value) }}
                    onClick={() => handleSelect(option.value)}
                  >
                    {option.label}
                  </Box>
                ))}
              </Box>
              {isRemovable && (
                <IconButton
                  disableFocusRipple
                  onClick={handleRemove}
                  classes={{ root: classes.deleteButton }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default BaseMoneyInput;
